














import { Component, Vue, Prop } from 'vue-property-decorator'
import ConfirmModalOptions from '@/app_code/Modals/Options/ConfirmModalOptions'

@Component
export default class BusinessBranchPickerModal extends Vue {
  @Prop()
  private options!: ConfirmModalOptions;

  yesClicked () {
    if (this.options.OnYes) this.options.OnYes()

    this.$emit('close')
  }

  noClicked () {
    if (this.options.OnNo) this.options.OnNo()

    this.$emit('close')
  }
}
